<script>
    // -- VARIABLES

    export let type = 'text';
    export let name = '';
    export let placeholder = '';
    export let required = false;
    export let value = null;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';

    // -- CLASSES

    .auth-form-input
    {
        padding: 1rem 1.25rem;
    }

    .auth-form-input input,
    .auth-form-input input:-webkit-autofill,
    .auth-form-input input:-webkit-autofill:focus
    {
        width: 100%;

        background-color: grayColor950 !important;

        color: blackColor !important;
    }

    .auth-form-input input:focus-visible
    {
        outline: unset;
    }

    input:is( :-webkit-autofill, :autofill, :-internal-autofill-selected )
    {
        -webkit-box-shadow: 0 0 0 30px grayColor950 inset;
        box-shadow: 0 0 0 30px grayColor950 inset;
    }
</style>

<div class="auth-form-input">
    <input
        { ...{ type } }
        name={ name }
        placeholder={ placeholder }
        required={ required }
        bind:value={ value }
    />
</div>
