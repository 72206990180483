<script>
    // -- IMPORTS

    import { onMount } from 'svelte';
    import { fade, slide } from 'svelte/transition';
    import { enableScroll, disableScroll } from '$lib/scroll';

    // -- CONSTANTS

    const maxWidthInEmMediaScreen = window.matchMedia( '(max-width: 56em)' );

    // -- VARIABLES

    export let isOpen = false;
    let isMobileScreen = maxWidthInEmMediaScreen.matches;
    let transitionType = isMobileScreen ? slide : fade;

    // -- FUNCTIONS

    function handleResizeEvent(
        )
    {
        isMobileScreen = maxWidthInEmMediaScreen.matches;

        transitionType = isMobileScreen ? slide : fade;
    }

    // -- STATEMENT

    onMount(
        () =>
        {
            window.addEventListener( 'resize', handleResizeEvent );

            return () => window.removeEventListener( 'resize', handleResizeEvent );
        }
        );

    // ~~

    $:
    {
        if ( isOpen )
        {
            disableScroll();
        }
        else
        {
            enableScroll();
        }
    }

</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .modal-root
    {
        overflow: hidden;
        max-height: 90%;
        width: 100%;
        border-radius: 1.5rem 1.5rem 0 0;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);

        +media( desktop )
        {
            max-width: 36rem;
            border-radius: 1.5rem;
        }
    }

    .modal-overlay
    {
        z-index: overlayZIndex;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        height: 100%;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: flex-end;

        background: rgba(0,0,0,0.5);

        +media( desktop )
        {
            align-items: center;
        }
    }
</style>

{#if isOpen }
    <div class="modal-overlay" transition:fade>
        <div class="modal-root" transition:transitionType>
            <slot/>
        </div>
    </div>
{/if}
