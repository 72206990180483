<script>
    // -- IMPORTS

    import { Link } from 'svelte-routing';
    import { getLocalizedTextBySlug } from 'senselogic-gist';
    import { languageTagStore } from '$store/languageTagStore';
    import { authModalStore } from '$store/authModalStore';
    import { profileSignedInStore } from '$store/profileStore';
    import { get } from 'svelte/store';
    import BigButton from '$component/element/BigButton.svelte';
    import AnimatedBanner from './AnimatedBanner.svelte';
    import { onDestroy } from 'svelte';
    import DivBackground from '../../element/DivBackground.svelte';

    // -- CONSTANTS

    const dirPath = '/image/home/banner/iphone/';

    // -- VARIABLES

    $: isAuthenticated = $profileSignedInStore;

    // -- STATEMENTS

    let unsubscribe = profileSignedInStore.subscribe(
        ( profileSignedIn ) => isAuthenticated = profileSignedIn
        );

    // ~~

    onDestroy(
        () =>
        {
            unsubscribe();
        }
        );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    .home-main-banner
    {
        position: relative;

        width: 100%;
        padding-bottom: 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        +media( desktop )
        {
            padding-bottom: 180px;
        }
    }

    .home-main-banner-content
    {
        position: relative;

        margin-top: 5vh;
        height: auto;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .home-main-banner-text
    {
        width: 100%;
        padding: 0 1rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        +media( desktop )
        {
            padding: 0;
        }
    }

    .home-main-banner-text h1
    {
        padding: 1rem 0;

        line-height: 3.5rem;
        font-size: 3rem;
        font-weight: 700;
        letter-spacing: -0.48px;
        text-align: center;
        color: blueColor100;

        +media( desktop )
        {
            line-height: 4.5rem;
            font-size: 4rem;
        }
    }

    .home-main-banner-text p
    {
        line-height: 1.5rem;
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        color: grayColor300;
    }

    .iphone-container
    {
        position: relative;

        width: 100%;
        aspect-ratio: 1/1;
        padding: 2rem 0;

        display: flex;
        justify-content: center;
        align-items: center;

        +media( desktop )
        {
            height: 65vh;
        }
    }

    .iphone
    {
        position: relative;

        margin-bottom: 2rem;
        height: 40rem;
        width: 76rem;

        display: none;

        animation: float 3s ease-in-out infinite;

        +media( tablet )
        {
            max-height: 27rem;
            max-width: 50rem;

            display: block;
        }

        +media( desktop )
        {
            display: block;
        }
    }

    .iphone-mobile
    {
        position: relative;

        width: 75%;
        aspect-ratio: 1/1;

        display: block;

        animation: float 3s ease-in-out infinite;

        +media( tablet )
        {
            display: none;
        }

        +media( desktop )
        {
            display: none;
        }
    }

    @keyframes float
    {
        0%,
        100%
        {
            transform: translate( 0, 0px );
        }

        50%
        {
            transform: translate( 0, 15px );
        }
    }
</style>

<section class="home-main-banner">
    <AnimatedBanner/>
    <div class="home-main-banner-content">
        <div class="home-main-banner-text">
            <h1>
                { getLocalizedTextBySlug( 'blog-slogan-label', $languageTagStore ) }
            </h1>
            <p>
                { getLocalizedTextBySlug( 'blog-discover-description-label', $languageTagStore ) }
            </p>
        </div>
        <!-- <Link to="/search"> -->
            <div class="iphone-container">
                <div class="iphone-mobile">
                    <DivBackground
                        isGlobal={ true }
                        imagePath={ '/global/home/banner/iphone_mobile.avif' }
                        preload={ true }
                        highRes={ 1280 }
                    />
                </div>
                <div class="iphone">
                    <DivBackground
                        isGlobal={ true }
                        imagePath={ '/global/home/banner/iphone_desktop.avif' }
                        preload={ true }
                        highRes={ 1280 }
                    />
                </div>

            </div>
        <!-- </Link> -->
        <!-- {#if !get( profileSignedInStore ) }
            <BigButton
                text={ 'auth-sign-in-button' }
                onClick={ () => ( $authModalStore = 'sign-in' ) }
            />
        {:else} -->
            <BigButton
                text={ 'join-beta-moien-label' }
                onClick={ () => ( $authModalStore = 'beta-applicant' ) }
            />
        <!-- {/if} -->
    </div>
</section>
